import React, { useState, useCallback , useContext} from 'react'
import {Navigate, NavLink } from 'react-router-dom'
import {auth} from '../Utils/Firebase'
import { signInWithEmailAndPassword} from 'firebase/auth'
import { AuthContext } from '../Utils/Auth';
import {toast, ToastContainer} from 'react-toastify'
import '../Style/login.css'
function Login() {

  const {setCurrentuser} = useContext(AuthContext)
    const handleSubmit = useCallback(async e => {
        e.preventDefault()
    
        const { userName, password } = e.target.elements
       
        try {
          await signInWithEmailAndPassword(auth, userName.value, password.value)
          auth.onAuthStateChanged(setCurrentuser)
        
          return <Navigate to="/dashboard"/>
        } catch (e) {
          toast.warn(e.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
         // eslint-disable-next-line
      }, [])


    const [user, setUsername ] = useState({
        userName:"",
        password:"" 
    });

    const handleChange =(e)=>{
        const name= e.target.name; 
        const value = e.target.value; 
        setUsername({...user,[name]:value})
    }


  if(auth.currentUser) return <Navigate to="/dashboard"/>

  return (
    <div className='loginContainer'>
    <form onSubmit={handleSubmit}>
        <label>User Name</label>
        <input type='text' value={user.userName} onChange={handleChange} name="userName"/>
        <label>Password</label>
        <input type="password" value={user.password} onChange={handleChange} name="password" autocomplete="current-password"/>
        <div className='groupBtn'>
                <button type='submit' className='joinPortalBtn'>Login</button>
                <button type='cancel' className='joinButton CancelPortalBtn' ><NavLink to="/">Cancel</NavLink></button>
        </div>
    </form>
    <ToastContainer/>
    </div>
  )
}

export default Login