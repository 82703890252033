import React , {useEffect,useCallback, useRef, useState  } from 'react'
import { ProgramsData } from '../Assets/Data/ProgramsData'
import { AiFillCheckCircle } from 'react-icons/ai';
import '../Style/modal.css'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {db} from '../Utils/Firebase' 
import {ref,set} from 'firebase/database'

function Modal({open,close, programId}) {
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const SubscriberFrom = useRef()

    const [subscriber, setSubscribers]=useState(
      {
        user_name:"",
        user_email: "",
        user_phone: "", 
        user_program: "", 
      }
    );
    const handleChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value;
      setSubscribers({ ...subscriber, [name]: value });

    }
    const [selectedProgram, setSelectedProgram]=useState()

    const handleEscKey = useCallback((event) => {
        if (event.key === KEY_NAME_ESC) {
            close();
            // eslint-disable-next-line
            programId=null; 
        }
    }, [close]);
    
   

    useEffect(() => {
      

        ProgramsData
        .filter((prog) => prog.id === programId)
        .map(prog=> setSelectedProgram(prog.title))

    

        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

    
        setSubscribers({...subscriber, user_program : selectedProgram})
       

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
        // eslint-disable-next-line 
      }, [handleEscKey, programId]);
      
      
    const handleSubmit=(e)=>{
        e.preventDefault();
        
          emailjs.sendForm('service_figuyv8', 'template_81l5o4p', SubscriberFrom.current, 'm1OXQ0cGaN2PriDcB')
          .then((result) => {
              toast.info('Your subscribed successfully, our team will contact you shortly', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                set(ref(db,'subscribers/'+ Date.now()),subscriber)
                SubscriberFrom.current.reset();
                setSubscribers(      
                  {
                  user_name:"",
                  user_email: "",
                  user_phone: "", 
                  user_program: "", 
                  }
                )
                programId=null; 
                setTimeout(close(), 5000);
                
              console.log(result.text);
          }, (error) => {
            toast.warn('Something went wrong Try Again Later', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              console.log(error.text);
          });
      
         
        
      };
    if (!open) return null;
    if (programId ==null) return null;

    return (
        <div className='Modal'>
      <div className='modalOverLay'>
        <h1 className='closeBtn' onClick={close} >X</h1>

        <div className='modalContainer'>
        <div className='modalSelectedProgram'>
            <h4>Your Selected Programs</h4>
        {ProgramsData
        .filter((prog) => prog.id === programId)
        .map(prog=>{
            
            return (
            <div key={prog.id}>

                <h4>{prog.title[0]} <br/> {prog.title[1]} <br/>{prog.title[2]}</h4>
                <hr />
                <ul className='programDetailsList'>
                {prog.Description.map((f ,index) =>{
                   
                    return (
                        
                        <li key={index}><AiFillCheckCircle className='programDetailsListIcons'/> {f}</li>
                        )
                })}
                </ul>
            </div>
            )
        })}
        </div>
            <form ref={SubscriberFrom} onSubmit={handleSubmit}>
                <label>Name</label>
                <input type="text" onChange={handleChange} value={subscriber.user_name} placeholder='Enter your first, last name' required name="user_name" />
                <label>PhoneNumber</label>
                <input type="text" name="user_phone" onChange={handleChange} value={subscriber.user_phone}  placeholder='Enter your phone number' required />
                <label>Email</label>
                <input type='text' onChange={handleChange}  name='user_program' value={subscriber.user_program} hidden />
                <input type="email" onChange={handleChange} value={subscriber.user_email} name="user_email" placeholder='Enter your email address' required />
                <div className='groupbtn'>
                <button type="submit" className='joinPortalBtn'>Join</button>
                <button type="cancel" className='joinButton CancelPortalBtn' onClick={close}>Cancel</button>

                </div>
            </form>
        </div>
            
      </div>
      <ToastContainer/>
    </div>
 
  )
}

export default Modal
