// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database'
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPL3oE7lsHQsn0K5qlanNEDWS21kynlEc",
  authDomain: "cina-ff45f.firebaseapp.com",
  databaseURL: "https://cina-ff45f-default-rtdb.firebaseio.com",
  projectId: "cina-ff45f",
  storageBucket: "cina-ff45f.appspot.com",
  messagingSenderId: "790598203992",
  appId: "1:790598203992:web:9c297df48625c8cd19e82d",
  measurementId: "G-FSWYXFKFM2"
};

const app = initializeApp(firebaseConfig); 

// Initialize Firebase
export const auth = getAuth(app)
export const db = getDatabase(app)