import React , {useState, useContext} from 'react'
import '../Style/dashboard.css'
import {db , auth} from '../Utils/Firebase'
import { Navigate } from 'react-router-dom'
import {ref, onValue} from 'firebase/database'
import { ProgramsData } from '../Assets/Data/ProgramsData'
import {BiExit} from 'react-icons/bi'
import { AuthContext } from '../Utils/Auth';
import { signOut } from 'firebase/auth'

export default function Dashboard() {

  const {currentUser} = useContext(AuthContext)
  const [progdata , setprodata] = useState()
  const [msgData,setMsgData]=useState();
  const [msgSub,setMsgSub] = useState(false);
  const [sub,setSub] = useState()

  const handleMessages =()=>{
    setMsgSub(false)
    const msgRef= ref(db,'emails/'); 
    onValue(msgRef,(snap)=>{
      const msgData = snap.val();
      const msgDataArray= Object.entries(msgData)
      setMsgData(msgDataArray)
    })
  }

  const handleSubscribers =()=>{
    setMsgSub(true)
    setprodata(ProgramsData)
    const subRef= ref(db,'subscribers/'); 
    onValue(subRef,(snap)=>{
      const subData = snap.val();
      const subDataArray = Object.entries(subData)
      setSub(subDataArray)
    })
  }
const handleLogout =()=>{
  if (currentUser) {
    signOut(auth)
    
  } else {
    return <Navigate to="/login"/>
  }
}


  return (
    <div className='dashboard'>
      <div className='dashboardSide'>
            <div className='active' onClick={handleSubscribers}>Subscribers</div>
            <div onClick={handleMessages} >Customer Messages</div>
            <div onClick={handleLogout}><BiExit/></div>
      </div>
      <div className='dashboardMain'>
        {msgSub? sub && 
        sub.map(data=>{
          return (
            <ul className='Subscriber' key={data[0]}>
            <li><span className="subscriberListItems">Customer Name:</span>  {data[1].user_name}</li>
            <li><span className="subscriberListItems">Selected Program:</span> {progdata && 
              progdata
              .filter((prog) => prog.id === data.user_program)
              .map(prog=> {
                return (
                  <h1>{prog.id}</h1>
                )
              })
            }</li>
            <li><span className="subscriberListItems">Phone Number:</span> {data[1].user_phone}</li>
            <li><span className="subscriberListItems">Email:</span>  {data[1].user_email}</li>
            <li><span className="subscriberListItems">Date:</span>  {data[0]}</li>
      </ul>  
          )
        })     
        :
        msgData && 
        msgData.map(data=>{   
          return(
            <ul className='Subscriber' key={data[0]}>
            <li><span className="subscriberListItems">Customer Name:</span> {data[1].user_name}</li>
            <li><span className="subscriberListItems">Email:</span> {data[1].user_email}</li>
            <li className='customerMessage'><span className="subscriberListItems">Message: 
            </span> {data[1].user_message}</li>
            <li><span className="subscriberListItems">Date:</span>  {data[0]}</li>
      </ul>  
          )
        })   
        }             
      </div>
    </div>
  )
}
