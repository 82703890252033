import React,  { useRef , useState} from 'react'
import '../Style/contact.css'
import emailjs from '@emailjs/browser';
import {db} from '../Utils/Firebase' 
import {ref,set} from 'firebase/database'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactSvg from '../Assets/SVGs/ContactSvg'
export default function Contact() {

  const form = useRef();
  // const [formState, setFormState] = useState({
  //   user_name: "",
  //   user_email: "",
  //   user_message: "",
  //  });
  // const handleInputChange = function (e) {
  //   setFormState({
  //     ...formState,
  //     [e.target.name]: e.target.value,
  //   });
  //   };
   
    const [message,setMessage] = useState(
      {
        user_name: "",
        user_email: "", 
        user_message: ""
      }
    )
    const handleChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value;
      setMessage({ ...message, [name]: value });

    }
  const sendEmail = (e) => {
    e.preventDefault();
      
      emailjs.sendForm('service_figuyv8', 'template_zytf9fv', form.current, 'm1OXQ0cGaN2PriDcB')
      .then((result) => {
          toast.info('Your Message Send Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          set(ref(db,'emails/'+Date.now()),message)
          setMessage(
            {
              user_name: "",
              user_email: "", 
              user_message: ""
            }
          )
          form.current.reset();
          console.log(result.text);
      }, (error) => {
        toast.warn('Something went wrong Try Again Later', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          console.log(error.text);
      });
  
     
    
  };
  return (
    <section className='contactPage'>

    <ContactSvg />
     <form ref={form}  onSubmit={sendEmail}>
     <h4>Contact Us</h4>
     <p>Explore the future with us. feel free to get in touch</p>
      <label>Name</label>
      <input required placeholder="First Name" type='text' value={message.user_name} onChange={handleChange} name="user_name"  />
      <label>Email</label>
      <input required placeholder="Email address" type='text' value={message.user_email} onChange={handleChange} name="user_email" />
      <label>Message</label>
      <textarea required rows={6} placeholder="How we can get better?" value={message.user_message} onChange={handleChange} name="user_message" />
      <button  type='submit' className='btnFormContactPage'>Send Message</button>
     </form>
     <ToastContainer />
    </section>
  )
}
