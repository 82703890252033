import React from 'react'
import '../Style/termspolicy.css'
function TermsOfUs() {
  return (
    <div className='termsPolicyContainer'>
      <h1>Terms of Use</h1>

<p>Welcome to our Art Website! Please read and understand the following Terms of Use before using our services.</p>

<h4>1. Acceptance of Terms</h4>
<p>By accessing or using our Art Website, you acknowledge that you have read, understood, and agreed to these Terms of Use. If you do not agree with any part of these terms, you should not use our services.</p>

<h4>2. Intellectual Property</h4>
<p>All content available on our Art Website, including but not limited to art programs, HTML art lessons, images, graphics, text, videos, and software, is protected by intellectual property laws and belongs to us or our licensors.</p>
<p>You may not modify, reproduce, distribute, transmit, display, perform, or create derivative works from any content on our website without obtaining explicit written permission from us.</p>

<h4>3. Use of Services</h4>
<p>Our Art Website provides art programs and HTML art lessons for learning purposes. You may access and use these resources for personal, non-commercial use only.</p>
<p>You may not use our services for any unlawful, fraudulent, or malicious activities, or to infringe upon the rights of others.</p>
<p>You are responsible for maintaining the confidentiality of your account information, including your username and password, and for all activities that occur under your account.</p>

<h4>4. User-Generated Content</h4>
<p>Our Art Website may allow users to submit or upload their own art, comments, feedback, or other content ("User-Generated Content").</p>
<p>By submitting User-Generated Content, you grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, perform, and display such content.</p>
<p>You represent and warrant that you have all necessary rights to grant the above license, and that your User-Generated Content does not infringe upon the rights of any third party.</p>

<h4>5. Limitation of Liability</h4>
<p>Our Art Website and its services are provided on an "as is" and "as available" basis. We do not warrant that the services will be uninterrupted, error-free, or free from viruses or other harmful components.</p>
<p>We shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with the use of our services.</p>
<p>We reserve the right to modify, suspend, or terminate our services at any time without prior notice.</p>

<h4>6. Privacy</h4>
<p>We collect and process personal data in accordance with our Privacy Policy. By using our services, you consent to the collection and processing of your personal data as described in the Privacy Policy.</p>

<h4>7. Governing Law and Jurisdiction</h4>
<p>These Terms of Use shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in [insert jurisdiction].</p>

<h4>8. Modifications to Terms of Use</h4>
<p>We reserve the right to modify or update these Terms of Use at any time. Any changes will be effective immediately upon posting on our Art Website. It is your responsibility to review these terms periodically for any updates.</p>

<p>By using our Art Website, you agree to comply with these Terms of Use. If you have any questions or concerns about these terms, please contact us.</p>
    </div>
  )
}

export default TermsOfUs
