export const ProgramsData =
    [
        {
            "id": 1,
            "title": [
                "ART",
                "PAINTING",
                "PROGRAM"
            ],
            "Description": ["Realism", "Photorealism", "Painterly art", "Abstract art", "Expressionism", "Cubism", "Minatory"],
            "className": "progtwo",
            "buttonName": "Paint with us"
        },
        {
            "id": 2,
            "title": [
                "Sclu",
                "pture",
                "PROGRAM"
            ],
            "Description": ["Molded", "Cast", "Carved", "Assembled"],
            "className": "progone",
            "buttonName": "Play with us"
        },

        {
            "id": 3,
            "title": [
                "Dance",
                "PLIÈ",
                "PROGRAM"
            ],
            "Description": ["Belly Dancing", "Ballet", "Ballroom", "Contemporary", "Hip Hop", "Modern Dance", "Indian Dance forms"],
            "className": "progthree",
            "buttonName": "Pliè with us"
        },
        {
            "id": 4,
            "title": [
                "Music",
                "Play",
                "PROGRAM"
            ],
            "Description": ["Piano", "Keyboard", "Violin", "Guitar", "Indian Tabla", "Flute"],
            "className": "progthree",
            "buttonName": "Pliè with us"
        },
        {
            "id": 5,
            "title": [
                "Photography",
                "Phase",
                "PROGRAM"
            ],
            "Description": ["Portrait", "Fashion", "Editorial", "Architectural", "Landscape",],
            "className": "progthree",
            "buttonName": "Pliè with us"
        },
        {
            "id": 6,
            "title": [
                "Health",
                "Fitness",
                "PROGRAM"
            ],
            "Description": ["Yoga", "Daily workout", "Swimming", "Dancing", "Basketball", "Soccer", "Cricket"],
            "className": "progthree",
            "buttonName": "Pliè with us"
        },
   {
            "id": 8,
            "title": [
                "Consulate",
                "Special",
                "People"
            ],
            "Description": ["Health", "Fitness", "Photography", "Music", "Sclupture"],
            "className": "progthree",
            "buttonName": "Pliè with us"
        }
    ]