import React, {useState} from 'react'
import '../Style/home.css'
import Modal from './Modal'
import brushes from '../Assets/Images/brushes.png'
import Medua from '../Assets/Images/medusa.jpg'
import Child from '../Assets/Images/savesoil.jpg'
import yoga from '../Assets/Images/standyoga.jpg'
import piano from '../Assets/Images/guitar.png'
import ITSSLogo from '../Assets/Images/itssLogo.jpeg'
import { ToastContainer} from 'react-toastify';
import { Link } from 'react-router-dom'
export default function Home() {
  const [protalOpen, SetIsPortalOpen] = useState(true);
  const [programId, setProgramId] = useState()
  const handelJoinBtn=(programID)=>{
    SetIsPortalOpen(true)
    setProgramId(programID)
  }
  
  return (
    <section className='LandingPage'>
{protalOpen &&

<Modal open={protalOpen} programId={programId}  close={() => SetIsPortalOpen(false)}></Modal>
}
      <div className='meduaSection'>
        <h1 className='medusaText'>
          CINA ARTs and <span className='SpecialColor'> Wellness </span>
          is a professional
          organization and <span className='SpecialColor'>community</span> learning 
          platform.
        </h1>
        <div className='medusaImageContainer'>
          <img src={Medua} alt="Medusa "/>
        </div>
      </div>

      <div  className='banner'>
      <div className='textBanner'>
        <h4>CINA aims to transform the
            health of our clients by using
            art to inspire better health
            and well-being of our
            clients, and our
            communities.
        </h4>
        <p>Join our community and experience
        the benefits today!</p>
      </div>
       <div className='imagebanner'> 
        <img src={brushes} alt="brushes"/>
       </div>
      </div>


      <div className='programs'>
        <div className='imagesavesoil savesoilLeft'>
          <img src={Child} alt="join our Programs"/>
        </div>
        <div className='textDescription'>
              <h4>Art and <span className='SpecialColor'>Painting</span><br/> Program</h4>
              <p>Realism Photorealism, Painterly Art,<br/> Abstract Art, Expresionism and cubism</p>
              <button className='joinButton CancelPortalBtn' onClick={()=>handelJoinBtn(1)}> Join </button>
        </div>
      </div>
      <div className='programs programReverse'>
        <div className='textDescriptionsReverse'>
              <h4>Health and <span className='SpecialColor'>Fitness</span> <br/>Program</h4>
              <p>Yoga Techniques,Daily Training Programs, Swimming<br/>
Maneuvers, Dancing Steps,Basketball Formations<br/>
,Soccer Strategies and Cricket.</p>
              <button className='joinButton CancelPortalBtn' onClick={()=>handelJoinBtn(6)}> Join </button>
        </div>
        <div className='imagesavesoil yogaPoster'>
          <img src={yoga} alt="join our Programs"/>
        </div>
      </div>
      <div className='partnerCard'>
      <div className='partnerHeading'>
          <h3>Our Partners</h3>
          <p>People who we trust and work to achieve common goals</p>
      </div>
      <div className='partneritems'>
        <div className='innerCards'>
          <div className='partnerImageLogo'>
            <img src={ITSSLogo} alt='Information Technolgy Skill Set' />
          </div>
          <h4><Link to={''}>Information Technology Skill Set </Link></h4>
        </div>
      </div>
      </div>
      <div className='MusicPlayContainer'>
        <div className='firstColumn'>
            <div className='firtcolImage'>
                  <img src={piano} alt="Music Play Program"/>
            </div>
            <div className='firtColText'>

            <h3>Music<span className='SpecialColor'> Play </span> <br />Program</h3>
            <h4>Piano, Keyboard, Violin, Guitar, <br /> Indian Table and Flute</h4>
            <button className='MusicBtnJoin' onClick={()=>handelJoinBtn(4)}> Join </button>
            </div>
        </div>
        <div className='secondColumn'>
              <div className='subPrograms'>
                <h5>Piano</h5>
                <p>The piano is a keyboard instrument that produces sound by
striking strings with hammers. It was invented in Italy in the early
18th century and quickly became popular for its ability to vary
volume and tone. Today, the piano is widely used in many
different genres of music and is considered a fundamental
instrument for musicians and music lovers alike.</p>
              <hr className='subprogramHL' />
              </div>
              <div className='subPrograms'>
                <h5>Violin</h5>
                <p>The violin is a stringed musical instrument that originated in Italy
in the 16th century. It is played with a bow and is used in a variety
of musical genres. It remains one of the most popular and
versatile instruments in the world.</p>
              <hr className='subprogramHL' />
              </div>
              <div className='subPrograms'>
                <h5>Guitar</h5>
                <p>The guitar is a stringed musical instrument with its origins in
Spain. It evolved from the lute in the 16th century and is typically
played by plucking or strumming the strings. It is widely used in
many musical genres and remains one of the most popular
instruments in the world.</p>
              </div>
        </div>
      </div>
      <div className='SpecialContainer'>
          <div className='specialCol1'>
            <h3>We Provide Consulate for <span className='SpecialColor'>Special People</span></h3>
            <p>Start Optimizing your process today.</p>
            <button className='signUpButton' onClick={()=>handelJoinBtn(8)}>Sign up now</button>
          </div>
          <div className='specialCol2'>
             
          </div>
        </div>
        <ToastContainer />
    </section>
  )
}
