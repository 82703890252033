import React, {useState, useEffect} from'react'
import {auth} from './Firebase'
export const AuthContext = React.createContext();


export const AuthProvider = ({children})=>{

    const [currentUser, setCurrentuser] = useState();


    useEffect(()=>{

        // onAuthStateChanged(auth, (user) => {
        //     if (user) {
        //       setCurrentuser(user);
        //     } else {
        //       setCurrentuser(null);
        //     }
        //   });


    auth.onAuthStateChanged(setCurrentuser)
    },[])
    
    
    return (
        <AuthContext.Provider
            value={{currentUser, setCurrentuser}}
        >
            {children}
        </AuthContext.Provider>
    )
}