import React,{useState} from 'react'
import '../Style/nav.css'
import { Link } from "react-router-dom";
import {CiMenuBurger} from 'react-icons/ci'
import {AiOutlineClose} from 'react-icons/ai'
export default function Navigation() {

  const [toggleNav,setToggleNav]=useState(false);
	const [logo, setLogo] = useState(false)

  return (
    <>
    <h1 className="ToggleNav" onClick={()=>setToggleNav(!toggleNav)}>{toggleNav? <AiOutlineClose/>:<CiMenuBurger/>}</h1>
    <nav className='Navigation'>
    <ul className='NavLinkList'>
      <li className='logoTextPic' onMouseEnter={() => setLogo(true)}  onMouseLeave={() => setLogo(false)}>
          {logo ?"" : "CINA"}
      </li>
      <div className='groupOneNav'>
      <li className='NavLinkItems'><Link  to={`/`} >Home</Link> </li>
      <li className='NavLinkItems'><Link  to={`programs/`} >Programs</Link></li>  
      <li className='NavLinkItems'><Link  to={`about_us/`} >About Us</Link> </li>
      <li className='NavLinkItems'><Link  to={`contact/`} >Contact</Link></li>
      </div>
    </ul>
    
      
    </nav>
    {toggleNav && 
    <div>
    <ul className='MobNavLinkList'>
        <li className='logoTextPic' onMouseEnter={() => setLogo(true)}  onMouseLeave={() => setLogo(false)}>
            {logo ?"" : "CINA"}
        </li>
        <div className='groupOneNav'>
        <li className='mobNavLinkItems'><Link onClick={()=>setToggleNav(false)} to={`/`} >Home</Link> </li>
        <li className='mobNavLinkItems'><Link onClick={()=>setToggleNav(false)} to={`programs/`} >Programs</Link></li>  
        <li className='mobNavLinkItems'><Link onClick={()=>setToggleNav(false)} to={`about_us/`} >About Us</Link> </li>
        <li className='mobNavLinkItems'><Link onClick={()=>setToggleNav(false)} to={`contact/`} >Contact</Link></li>
        </div>
    </ul>
    </div>


    }
    </>
  )
}
