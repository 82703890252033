import React, {useState} from 'react'
import arrowDown from '../Assets/Images/screen/Screenshot_7.png'
import Yoga from '../Assets/Images/screen/setting_Yoga.png'
import Modal from './Modal'
import '../Style/aboutus.css'
export default function AboutUs() {
  const [protalOpen, SetIsPortalOpen] = useState(true);
  const [programId, setProgramId] = useState()
  const handelJoinBtn=(programID)=>{
    SetIsPortalOpen(true)
    setProgramId(programID)
  }
  
  return (
    
    <div className='aboutUsContainer'>
      {protalOpen &&

<Modal open={protalOpen} programId={programId}  close={() => SetIsPortalOpen(false)}></Modal>
}
      <h3>About us</h3>
        <p className="aboutusPara">
            Cina Arts and Wellness is a Professional organization and
            community learning Platform.
            Here we will provide you only interesting content, which you
            will like very much.
            We're dedicated to providing you the best of organization and
            community learning, with a focus on dependability and learning
            arts artist dance music.
            We're working to turn our passion for organization and
            community learning into a booming online website.
            We hope you enjoy our organization and community learning as
            much as we enjoy offering them to you.
        </p>
        <img src={arrowDown} className="arrowP" alt="showing aboutus "/>
        <h3>Our mission</h3>
        <p className='ourmissionPara aboutusPara'>
            CINA aims to transform the health of our clients by
            using art to inspire better health and well-being of
            our clients, and our communities.
        </p>
      
        <div className='yogaPoseAboutUs'>
          <h3 className='yogaposeTitle'>
            Let's start working more efficiently today!
          </h3>
          <div className='yogapostImageContainer'>
            <img src={Yoga} alt="a Girl is setting in Yoga Pose" />
          </div>
        </div>

        <div className='SpecialContainer'>
          <div className='specialCol1'>
            <h3>We Provide Consulate for <span className='SpecialColor'>Special People</span></h3>
            <p>Start Optimizing your process today.</p>
            <button className='signUpButton' onClick={()=>handelJoinBtn(8)}>Sign up now</button>
          </div>
          <div className='specialCol2'>
             
          </div>
        </div>
    </div>
  )
}
