import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Style/index.css'
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import Contact from './Components/Contact';
import Programs from './Components/Programs';
import AdminPanel from './Components/AdminPanel';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsOfUs from './Components/TermsOfUs';
import Dashboard from './Components/Dashboard'
import Login from './Components/Login'
import ProtectedRoute from './Utils/ProtectedRoute'
import { AuthProvider } from './Utils/Auth';

import {
  Routes,
  BrowserRouter,
  Route 
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <BrowserRouter>
    <AuthProvider>
    <Routes>
        <Route path="/" element={<App />}>
        <Route path="/" index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about_us" element={<AboutUs   />} />
        <Route path="programs" element={<Programs />} />
        <Route path="admin_panel" element={<AdminPanel />} />
        <Route path="privacyAndPolicy" element={<PrivacyPolicy />} />
        <Route path="TermsOfUse" element={<TermsOfUs />} />
        <Route path="contact" element={<Contact />} />
        {/* <Route element={<ProtectedRoute />}> */}
        {/* <ProtectedRoute> */}

        <Route path="dashboard" element={ <ProtectedRoute> <Dashboard /> </ProtectedRoute> } />
        {/* </ProtectedRoute> */}
        {/* </Route> */}
        <Route path="login" element={<Login />} />
      </Route>
    </Routes>
    </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);


