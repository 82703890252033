import React from 'react'
import '../Style/termspolicy.css'
export default function PrivacyPolicy() {
  return (
    <section className='termsPolicyContainer'>
      <h2>Terms and Policies for Art Training and Learning Website</h2>

<p>Introduction Welcome to CINA Art and Wellness Website, a platform dedicated to providing art training and learning services to our customers.
   By using our website and availing our services, you agree to comply with the following terms and policies. 
  Please read them carefully.
  
  <h4>Services Offered</h4>
  <p>CINA Art and Wellness Website offers a variety of art training and learning services, including but not limited to dance lessons, sculpture workshops, flute classes, piano tutorials, and many other art disciplines. 
  Our services aim to provide comprehensive and engaging learning experiences to our customers.</p>
  <h3>Registration and User Accounts</h3>
  <p>To access our services, you may need to create a user account. You agree to provide accurate and up-to-date information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
  </p>
  <h4>Age Restrictions</h4>
  <p></p> Our services are available to individuals who are at least 13 years old. If you are under the age of 13, you must obtain consent from a parent or legal guardian to use our website and services.</p>
<h4>Payments and Fees</h4>
<p> Some of our services may require payment of fees. The pricing details and payment methods will be clearly communicated on our website. All payments are non-refundable unless otherwise stated in specific cases.</p>
<h4>Intellectual Property Rights</h4>
<p> The content provided on CINA Art and Wellness Website, including but not limited to text, images, videos, and interactive materials, are protected by intellectual property rights. You may use the content solely for personal and non-commercial purposes. Reproduction, distribution, or modification of the content without proper authorization is strictly prohibited.</p>
<h4>User Conduct</h4>
<p>You agree to use our website and services in a responsible and lawful manner. You must not engage in any activities that may disrupt the functioning of our website or infringe upon the rights of others. This includes but is not limited to spamming, hacking, or transmitting any harmful or offensive material.</p>
<h4>User-generated Content</h4>
<p>You may have the opportunity to contribute user-generated content, such as comments, reviews, or artwork, on our website. By submitting such content, you grant CINA Art and Wellness Website a non-exclusive, royalty-free, and worldwide license to use, reproduce, modify, and distribute the content for promotional and educational purposes related to our services.
Privacy Policy We value your privacy and handle your personal information in accordance with our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal data.</p>
<h4>Modifications to Terms and Policies</h4>
<p> CINA Art and Wellness Website reserves the right to update or modify these terms and policies at any time. Any changes will be effective immediately upon posting on our website. It is your responsibility to review the terms and policies periodically to stay informed about any updates.</p>
<h4>Termination</h4>
<p> We reserve the right to terminate your access to our website and services, with or without cause, at any time and without prior notice.</p>
<h4>Contact Information</h4>
<p> If you have any questions or concerns regarding these terms and policies, please contact us at +1 (289) 892-4243 or  cina.art.wellness@gmail.com.</p>

By using our website and services, you acknowledge that you have read, understood, and agreed to these terms and policies.
    </section>
  )
}
