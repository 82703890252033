import React from 'react'
import '../Style/footer.css'
import {Link } from 'react-router-dom'
export default function Footer() {
  return (
    <>
    <footer >
      <hr />
      <div className='pageFooter'>
        <div>
          <h6>CINA</h6>
          <p className='footerRight'>2023 &copy; All Right Reserved By
             ITSS</p>
        </div>
        <div>
        <ul className='footerList'>
        <h6> <li><Link to="/"> Home</Link></li></h6>
        <h6> <li><Link to="/programs"> Programs</Link></li></h6>
        <h6> <li><Link to="/about_us"> About Us</Link></li></h6>
        <h6> <li><Link to="/contact"> Contact</Link></li></h6>
       
          </ul>
        </div>
        <div>
          <ul>
          <h6><li><Link to="https://www.facebook.com/profile.php?id=100082419342530" target='_blank'>Facebook</Link></li></h6>
          <h6><li><Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FCArtwellness" target='_blank'> Twitter</Link></li></h6>
          <h6><li><Link to="https://www.instagram.com/cina.art.wellness/" target='_blank'> Instagram</Link></li></h6>
          </ul>
        </div>
        <div>
          <ul>
          <h6><li><Link to="/privacyAndPolicy"> Privacy Policy</Link></li></h6>
          <h6><li><Link to="/TermsOfUse"> Term of Us</Link></li></h6>
          </ul>
        </div>
        </div>
    </footer>
    </>
  )
}
