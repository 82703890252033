import React, {useState} from 'react'
import '../Style/programs.css'
import { ProgramsData } from '../Assets/Data/ProgramsData'
import { AiFillCheckCircle } from 'react-icons/ai';
import Modal from './Modal'
import running from '../Assets/Images/screen/runnings.png'
import { ToastContainer} from 'react-toastify';


export default function Programs() {
  const [protalOpen, SetIsPortalOpen] = useState(true);
  const [programId, setProgramId] = useState()
  const handelJoinBtn=(programID)=>{
    SetIsPortalOpen(true)
    setProgramId(programID)
  }
  return (
    <>
     
    {protalOpen &&

    <Modal open={protalOpen} programId={programId}  close={() => SetIsPortalOpen(false)}></Modal>
    }
    <div className='programsPage'>
      <h4>Programs</h4>
      <p>Our Pricing is not expensive, but it's not <br />
      cheap either, it's exactly what it should be </p>

      <div className='programsList'>
        {ProgramsData.map(program=>{
          return (
            <div className='programsTitle' key={program.id}>
              <h4>{program.title[0]}</h4>
              <h4>{program.title[1]}</h4>
              <h4>{program.title[2]}</h4>
              <button className='progJoinBtn' onClick={()=>handelJoinBtn(program.id)}>Join Now</button>
            </div>
          )
        })}
      </div>
      
      <h4>Program Details</h4>
      <div className='programDetails'>
      {ProgramsData.map(prog=>{
        return (
          <div key={prog.id}>

            <h4>{prog.title[0]} <br/> {prog.title[1]} <br/>{prog.title[2]}</h4>
            <hr />
            <ul className='programDetailsList'>
              {prog.Description.map((f ,index) =>{
                return (
                  <li key={index}><AiFillCheckCircle className='programDetailsListIcons'/> {f}</li>
                )
              })}
            </ul>
          </div>
        )
      })}
      </div>
      <div className='dailyworkoutsection'>
        <div className='runningImage'>
          <img src={running} alt="running for daily workout"/>
        </div>
          <h4>
          Daily Workout
          </h4>
          <p className='dailyWorkoutPara'>
            Cardiovascular exercise: Activities like running, cycling, swimming, or brisk walking that increase
            your heart rate and improve your cardiovascular health.
            Strength training: Exercises like weightlifting, bodyweight exercises, or resistance training that
            build muscle and improve overall strength and fitness.
            Flexibility training: Activities like stretching, yoga, or Pilates that improve flexibility, mobility, and
            range of motion.
            Core strengthening: Exercises that target the muscles in your core, including your abdominals,
            back, and hips, which help improve posture, balance, and stability.
            Rest and recovery: Taking time to rest and recover between workouts is also an important part of
            a daily workout routine. This may include activities like foam rolling, massage, or stretching.
          </p>
      </div>
      <div className='SpecialContainer'>
          <div className='specialCol1'>
            <h3>We Provide Consulate for <span className='SpecialColor'>Special People</span></h3>
            <p>Start Optimizing your process today.</p>
            <button className='signUpButton' onClick={()=>handelJoinBtn(8)}>Sign up now</button>
          </div>
          <div className='specialCol2'>
             
          </div>
        </div>
        <ToastContainer/>
    </div>
    </>
  )
}
